import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";


import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import WebSearch from "images/web-search.svg";
import DigitalHelp from "images/digital-help.svg";
import GoogleAds from "images/google-ads.svg";
import WebDevelopment from "images/web-development.svg";
import SocialMedia from "images/social-media.svg";
import EmailMarketing from "images/email-marketing.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-10`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({ cards = null, heading = "Amazing Features", subheading = "Features", description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." }) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    { imageSrc: GoogleAds, title: "Google Ads", description: "Our team of experts will craft a unique Google Ads Campaign that will give your business the boost it needs. We also specialized in getting your business the Google Guaranteed Badge." },
    { imageSrc: SocialMedia, title: "Social Media Ads", description: "Everyone is on social media today and we know how to turn their attention to generate revenue for your business. We can promote your business on Facebook and Instagram." },
    { imageSrc: WebDevelopment, title: "Web Development", description: "A website is the entry door to your business. We can develop a good design website guaranteed to catch your customer's attention." },
    { imageSrc: WebSearch, title: "SEO", description: "Paying for Ads is good when you need the extra boost; however, getting your customer to find you without costing you money is what we focus on." },
    { imageSrc: EmailMarketing, title: "Email Marketing", description: "Following up with your customers is essential for maintaining a steady source of revenue. We have the tools and skills to ensure your customers don't forget about you." },
    {
      imageSrc: DigitalHelp,
      title: "Digital Help",
      description: "Whether you need a QR code for your business or someone to manage your business profile, we are experts at that. Our team is here to help your business with all the technical help you need to succeed in the digital market."
    }
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
