import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/MiniCenteredFooter.js";
import workingProcessImg from "images/working-process.svg";
import teamWorkImg from "images/team-work.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import SimpleContactUs from "components/forms/SimpleContactUs";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      <Features
        subheading={<Subheading>Our work</Subheading>}
        heading={
          <>
            We have Amazing <HighlightedText>Services</HighlightedText><br />
            tailored for your business needs.
          </>
        }
        description={
          <>
          Our unique set of digital services focused entirely on meeting the needs of our clients and exceeding their expectations.
          </>
        }
      />
  
      <FeatureWithSteps
        subheading={<Subheading>Work Flow</Subheading>}
        heading={
          <>
           Our Working  <HighlightedText>Process</HighlightedText>
          </>
        }
        description={
          <>
          We approach every project with an eye for quality and a desire to create the best product possible.
          </>
        }
        textOnLeft={false}
        imageSrc={workingProcessImg}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
      <GetStarted/>
      <MainFeature2
        subheading={<Subheading>VALUES</Subheading>}
        heading={
          <>
            We Always Abide by Our <HighlightedText>Principles.</HighlightedText>
          </>
        }
        description = {
          <>
          We are a highly motivated team driven by a high-standards work ethic and core values. Our focus
on efficiency, quality, and client service has enabled us to become one of the most trusted marking agencies in our area.
          </>
        }
        imageSrc={teamWorkImg}
        showDecoratorBlob={false}
        features={[
          {
            Icon: MoneyIcon,
            title: "Affordable",
            description: "We work with your budget and promise to give you the most competitive prices compared with other agencies in the area.",
            iconContainerCss: tw`bg-green-300 text-green-800`
          },
          {
            Icon: BriefcaseIcon,
            title: "Professionalism",
            description: "We are a professional team with many years of experience in providing digital solutions. We guarantee our work and deliver nothing but best-in-class results.",
            iconContainerCss: tw`bg-red-300 text-red-800`
          }
        ]}
      />
      <Testimonial
        subheading={<Subheading>Testimonials</Subheading>}
        heading={
          <>
            Our Clients <HighlightedText>Love Us.</HighlightedText>
          </>
        }
        testimonials={[
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
            heading: "Amazing User Experience",
            quote:
            "I wanted to take this opportunity to thank you, your team, and your company for the outstanding marketing campaign that you ran for us. It truly gave out businesses the lift we needed in this very competitive market.",
            customerName: "Rachael",
            customerTitle: "Rhl Tree Services."
          },
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
            heading: "Love the Developer Experience and Design Principles!",
            quote:
              "The redesign of our website was no small feat. My vision was to create a modern, vibrant and welcoming website that reflected who we are as an organization. Fabre WebSvcs did an impressive job of designing a user-friendly website that accomplished just that. ",
            customerName: "Adam Cuppy",
            customerTitle: "Founder, PawSome SA"
          }
        ]}
      />  
      <SimpleContactUs />
      <Footer />
    </AnimationRevealPage>
  );
}
